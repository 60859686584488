<template>
  <div class="zq--achievement-widget">
    <div class="zq--achievement-widget--icon">
      <img
          v-if="widget.icon"
          src="../../assets/icons/achIcon.png"
          width="40"
          height="40"
          class="mr-1"
          alt="icon">
    </div>
    <!--   CONTENT  -->
    <div class="zq--achievement-widget--content">
      <span class="zq--achievement-widget--name">{{ widget.name }}</span>
      <div class="zq--achievement-widget--sub">
        <CInput
            readonly
        />
      </div>
    </div>
    <!--    STATUS  -->
    <div class="zq--achievement-widget--status">
      <CButton
          class="header-icon-buttons"
          variant="">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g>
            <g fill-rule="nonzero">
              <g>
                <g>
                  <g>
                    <path
                        d="M16.24 7.35c.36-.42.991-.469 1.41-.11.388.333.459.896.185 1.31l-.076.1-6 7c-.352.411-.96.463-1.374.139l-.092-.082-4-4c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083l.094.083 3.236 3.235 5.298-6.179z"
                        transform="translate(-784 -368) translate(56 96) translate(720 264) translate(8 8)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>

      </CButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    widget: Object
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";

.zq--achievement-widget {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  max-width: 380px;
  border: thick double grey;
  padding: 8px;

  margin-bottom: 1rem;

  @include media-breakpoint-down('576px') {
    max-width: 300px;
  }
  // icon
  .zq--achievement-widget--icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    flex: 1;
  }

  // content
  .zq--achievement-widget--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 3;

    .zq--achievement-widget--name {
      font-weight: bold;
      font-size: 16px;
    }

    .zq--achievement-widget--sub {

    }

    input {
      &:read-only {
        background: #fff;
      }
    }
  }

  // status
  .zq--achievement-widget--status {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
}
</style>